
body,
html {
  font-family: "Segoe UI", source-sans-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: -0.01rem;
  touch-action: manipulation;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  line-height: 1.5;
  width: 100%;
  background-color: white !important;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/*
 * class which rotates an image
 */
.rotate {
  position: absolute;
  width: 120;
  height: 120;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
